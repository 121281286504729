
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  props: {},
  components: { Breadcrumbs },
})
export default class MoveMember extends Vue {
  zzlApi: any;
  zzlFormat: any;
  condition: any = { idNumber: "" };
  columnFields: any = [];
  form: any = { oldCompanyId: "", companyId: "", isSubmit: false };
  companyOptions: any = [];
  searchOldCompanyOptions: any = [];
  searchCompanyOptions: any = [];
  formRules: any = {
    oldCompanyId: [{ required: true, message: "请选择转移机构", trigger: "change" }],
    companyId: [{ required: true, message: "请选择目标机构", trigger: "change" }]
  };
  get step() {
    let num = 0;
    if (this.form.oldCompanyId) num++;
    if (this.form.companyId) num++;
    if (this.form.isSubmit) num++;
    return num;
  }
  ///loadding
  resetCondition() {}
  //加载动画
  get texts() {
    return this.form.isSubmit
      ? ["is", "this", "so", "satisfying", "to"]
      : ["什么都没有~", "准备就绪", "转移结束"];
  }

  async onSubmit() {
    if (this.form.isSubmit) {
      this.$message.warning("转移未结束,请耐性等待~");
      return;
    }
    (this.$refs["form"] as HTMLFormElement).validate(async (valid: any) => {
      if (valid) {
        this.form.isSubmit = true;
        let res = await this.zzlApi.home.MigrateCompany(this.form);
        if (res.code == 0) {
          this.$message.success("转移成功");
        } else {
          this.$message.error("转移失败");
        }
        this.form.isSubmit = false;
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  async getCompany() {
    let res = await this.zzlApi.home.GetAllCompany();
    if (res.code == 0) {
      this.companyOptions = res.data;
    } else {
      this.$message.error("机构请求失败");
    }
  }
  remoteOldMethod(name: any) {
    if (!name) {
      this.searchOldCompanyOptions = [];
      return;
    }
    this.searchOldCompanyOptions = this.companyOptions.filter((f: any) => {
      return (
        f.name.indexOf(name) > -1 || f.company_abbreviation.indexOf(name) > -1
      );
    });
  }
  remoteMethod(name: any) {
    if (!name) {
      this.searchCompanyOptions = [];
      return;
    }
    this.searchCompanyOptions = this.companyOptions.filter((f: any) => {
      return (
        f.name.indexOf(name) > -1 || f.company_abbreviation.indexOf(name) > -1
      );
    });
  }

  initData() {
    this.getCompany();
  }
  mounted() {
    this.initData();
  }
}
